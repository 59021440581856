import React from 'react'
import logo from '../../img/logo.png'

export const Header = () => {
    return (
        <header className='center'>
            <img src={logo} alt=''/>
        </header>
    )
}
